<template>
  <div class="script-management-container">
    <!-- 表格主体 -->
    <div class="script-main">
      <div class="main-btn">
        <el-button type="primary" @click="goTo">创建脚本数据</el-button>
      </div>
      <!-- 表格 -->

      <el-table :data="infoList" tooltip-effect="dark" height="1%" class="customTable" style="width: 100%; margin-top: 20px">
        <el-table-column label="脚本名称" min-width="65px" align="center">
          <template slot-scope="scope">{{ scope.row.script_name }}</template>
        </el-table-column>

        <el-table-column prop="name" label="行业分类" align="center"> </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-link :underline="false" @click="copyData(scope.row.id)" type="primary" size="small">复制</el-link>
            <el-link :underline="false" @click="editBtn(scope.row.id)" type="primary" size="small">编辑</el-link>
            <el-link :underline="false" @click="delBtn(scope.row.id)" style="color: red" type="danger" size="small">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pages-center" :current-page="listPages.currentPageNum" :page-size="listPages.eachPageNum" layout="prev, pager, next, jumper" :total="listPages.total" @current-change="pageCurrentChange"> </el-pagination>
    </div>
  </div>
</template>

<script>
import { showScriptListData, delScriptListData, editScriptData, createScriptData } from "@/utils/apis";
export default {
  data() {
    return {
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      infoList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let params = {
        paging: "1",
        pageSize: this.listPages.eachPageNum,
        page: this.listPages.currentPageNum,
      };
      showScriptListData(params).then((res) => {
        if (res.code == 200) {
          this.infoList = res.data.list;
          this.listPages.total = res.data.total;
        }
      });
    },
    // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getList();
    },
    // 复制
    copyData(id) {
      editScriptData(id).then((res) => {
        if (res.code === 200) {
          let params = {
            script_name: res.data.script_name,
            category_id: res.data.category_id,
            flow_value: res.data.flow_value,
            studio_src: res.data.studio_src,
            studio_info_click: res.data.studio_info_click,
            studio_setting_click: res.data.studio_setting_click,
            total_time: res.data.total_time,
            goods_number: res.data.getGoodsSetting[0].goods_number,
            start_time: res.data.getGoodsSetting[0].start_time,
            end_time: res.data.getGoodsSetting[0].end_time,
            script_content: [],
            goods_content: [],
            bag_content: [],
            studio_name: res.data.studio_name,
          };
          res.data.getBag.forEach((item) => {
            let obj = {
              bag_id: item.bag_id,
              start_time: item.start_time,
              end_time: item.end_time,
            };
            params.bag_content.push(obj);
          });
          res.data.getWord.forEach((item) => {
            let obj = {
              minute: item.minute,
              seconds: item.seconds,
              script_content: item.script_content,
              key_word: item.key_word,
              miss_key_word: item.miss_key_word,
              turnover_rate: item.turnover_rate,
              barrage: item.barrage,
              start_time: item.start_time,
              level: item.level,
            };
            params.script_content.push(obj);
          });
          res.data.getGoods.forEach((item) => {
            let obj = {
              goods_id: item.goods_id,
              template_id: item.template_id,
            };
            params.goods_content.push(obj);
          });
          createScriptData(params)
            .then((result) => {
              if (result.code === 200) {
                this.$message({
                  type: "success",
                  message: result.msg,
                });
                this.getList();
              }
            })
            .catch((err) => {
              this.$message({
                type: "error",
                message: err,
              });
            });
        }
      });
    },
    // 编辑
    editBtn(id) {
      this.$router.push({
        path: "/scriptManagement/newScriptManagement/one",
        query: { id: id },
      });
    },
    // 删除
    delBtn(id) {
      this.$confirm("确定删除数据？", "提示消息", {
        confimButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let params = {
            id: id,
          };
          delScriptListData(params).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message.info("取消删除");
        });
    },
    // 跳转
    goTo() {
      this.$router.push({ path: "/scriptManagement/newScriptManagement" }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__fixed-right-patch {
  display: none;
}
.script-management-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
  .script-title {
    margin: 19px 0;
  }
  .script-main {
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .main-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
